#project {
    padding: 4rem 0;
    background: #f8f9fa;
  }
  
  .project-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .project-header h2 {
    font-family: 'Margarine, sans-serif';
    font-size: 2.5rem;
  }
  
  .project-header hr {
    width: 5%;
    border: 2px solid #007bff;
    margin: 1rem auto;
  }
  
  .project-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-col {
    display: flex;
    justify-content: center;
  }
  
  .project-card {
    width: 20rem;
    margin-bottom: 3rem;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
  }
  
  .project-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .project-card-title h1 {
    font-family: 'Margarine, calibri';
    font-weight: 400;
    font-style: normal;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .project-card-title {
    font-family: 'Margarine, calibri';
    font-weight: 400;
    font-style: normal;
  }
  