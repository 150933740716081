.our-team {
    padding: 0;
    text-align: center;
  }
  
  .section-title {
    margin-bottom: 2rem;
   font-family: " Margarine, sans-serif";
  }
  
  .team-member {
    margin-bottom: 2rem;
  }
  
  .member-image {
    width: 100%;
    padding-bottom: 100%; 
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .team-member:hover .member-image {
    transform: scale(1.1);
  }
  
  .member-info {
    padding: 0 1rem;
  }
  
  .member-info h3 {
    font-family: Margarine, calibri;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .member-info .title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-style: italic;
    color: #666;
  }
  
  .member-info p {
    font-size: 0.875rem;
    color: #333;
  }
  