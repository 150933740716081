.home-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .parallax {
    background-image: url('../img/3.webp');
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  h1 {
    font-size: 3rem;
    margin: 0;
    font-family: Margarine, calibri;
    font-weight: 600;
  }
  
  
  .cta-button {
    background-color: transparent;
    border: 2px solid #0056b3;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  