.course-title {
  text-align: center;
  padding: 25px 2px;
  margin-top: 25px;
  font-family: "Margarine", sans-serif;
}
.course-title hr{
  width: 5%;
    border: 2px solid #007bff;
    margin: 1rem auto;
}

.course .card-container {
  width: 20rem;
  height: 16rem; 
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease-in-out;
  overflow: hidden; 
  position: relative;
}

.card-container:hover {
  height: 25rem;
}

.course .card-img {
  width: 160px;
  height: auto;
  margin-top: 2rem;
}

.course .card-title {
  font-family: "Margarine", calibri;
  font-size: large;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  margin-top: 1rem; 
}

.course .card-text {
  font-size: 14px;
  font-family: "Margarine", calibri;
  font-weight: 100;
  font-style: normal;
  text-align: center;
  opacity: 0; 
  transition: opacity 0.3s ease-in-out; 
  padding: 1rem;
}

.course .card-container:hover .card-text {
  opacity: 1; 
}

@media screen and (max-width: 768px) {
  .course-title {
    padding: 15px 1px;
    margin-top: 15px;
  }

  .course .card-container {
    width: 100%;
    /* height: auto;  */
  }
}
