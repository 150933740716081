.review-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .review-card {
    width: 50%;
  }
  
  .review-card img {
    float: left;
    margin-right: 10px;
  }
  
  .review-card .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .carousel-indicators {
    bottom: -37px;
  }
  .review-carousel{
    width: 50%;       
  }
  @media (max-width: 768px) {
    .review-carousel {
      width: 80%; 
    }
  }
  
  @media (max-width: 576px) {
    .review-carousel {
      width: 100%; 
    }
  }