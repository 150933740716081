.custom-row {
    margin-bottom: 1rem;
  }
  
  .custom-row img {
    height: 16rem; 
  }
  
  .custom-row .col {
    padding: 0.5rem; 
  }
  