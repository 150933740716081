
.navbar-transparent {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }
  
  .navbar-colored {
    background-color: rgba(255, 255, 255, 0.9); 
    transition: background-color 0.3s ease-in-out;
  }
  .navbar-expanded {
    background-color: rgba(255, 255, 255, 0.9); 
  }