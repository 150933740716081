.bonus-card {
    width: 20rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .bonus-card:hover {
    transform: translateY(-10px);
  }
  
  .bonus-img {
    max-width: 10rem;
    max-height: 10rem;
    margin: 2rem 0;
  }
  
  .bonus-title {
    font-family: 'Margarine', sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
   
  }
  .bonus-texr{
    font-family: 'Margarine',calibri;
  }
  