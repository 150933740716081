#curriculum{
  background-color: #edeaea;
}
.section-title{
  padding: 2rem 0 0 0;
  font-family: Margarine,sans-serif;
}
.section-title hr{
  width: 5%;
    border: 2px solid #007bff;
    margin: 1rem auto;
}
.curriculum-carousel {
    max-width: 1200px;
    margin: auto;
    padding-bottom: 2rem;
  }
  
  .curriculum-carousel .card {
    height: 100%; 
    margin: 10px 0;
  }
  
  .curriculum-carousel .card img {
    height: 150px; 
    object-fit: cover;
  }
  

  